<template>
  <Table
    :loading="loading"
    :table-cols="tableCols"
    :table-data="tableData"
    :show-pagination="false"
  >
    <template v-slot="{ col, row }">
      <div v-if="col.prop === 'createTime'" class="flexc plan-time">
        <span>{{ row.createTime|parseTime('YYYY-MM-DD') }}</span>
        <span class="flex-c-c">{{ row.createTime|parseTime('HH:mm:ss') }}</span>
      </div>
      <div v-if="col.prop === 'needNum'">
        {{ numberCalculation(row.total,1,1000,'div') }}吨 {{ row.truckNum }}车
      </div>
      <div v-if="col.prop === 'map'" class="flex-c">
        <img class="cursor" src="@/assets/images/common/tansport.svg" @click="openMap(row)">
      </div>
      <div v-if="col.prop === 'goodsBillType'" class="order-type">
        <span>
          {{ $CONSTANT.goodsTypeMap.O[row.type]||'-' }}
        </span>
        <span v-if="+row.type===2" class="time">
          {{ row.extMap.bidExpireTime|parseTime }}
        </span>
      </div>
      <div v-if="col.prop === 'price'">
        <div v-if="+row.type===1">
          <span>{{ $parseNumber(row.price) }}{{ col.rulesObj[+row.priceUnit]||'-' }}</span>
          <span :class="['ml10',row.extMap?$CONSTANT.taxColorMap[+row.extMap.needTax]:'']"> {{ row.extMap?$CONSTANT.taxMap[+row.extMap.needTax]||'-':'-' }}</span>
        </div>
        <div v-else>-</div>
      </div>
      <div v-if="col.prop === 'lineName'">
        <div class="main-c-cursor" @click="toDetail(row)">
          <TextToolTip :text="`${row.loadAddrName||'-'}-${row.unloadAddrName||'-'}`" placement="none" />
        </div>
      </div>
      <div v-if="col.prop === 'realLoadNum'">
        {{ numberCalculation(row.realLoadNum,1,1000,'div') }}吨 {{ row.realLoadCarNum }}车
      </div>
      <div v-if="col.prop === 'realUnLoadNum'">
        {{ numberCalculation(row.realUnLoadNum,1,1000,'div') }}吨 {{ row.realUnLoadCarNum }}车
      </div>
      <div v-if="col.prop === 'loss'">
        {{ numberCalculation(row.realLoadNum,row.realUnLoadNum,1000,'minus') }}吨
      </div>
      <div v-if="col.prop === 'plan'" class="plan-time">
        <div>装:<span>{{ row.loadEarlyTime|parseTime }}</span></div>
        <div>卸:<span>{{ row.unloadEarlyTime|parseTime }}</span></div>
      </div>
    </template>
  </Table>
</template>

<script>
import { numberCalculation, parseTime } from '@/utils';
export default {
  filters: {
    parseTime,
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      numberCalculation,
      loading: false,
      tableCols: [
        { label: '线路名称', type: 'customize', prop: 'lineName', width: '155', fixed: 'left' },
        // { label: '发单时间', prop: 'createTime', type: 'filterTime', width: '165', fixed: 'left' },
        { label: '发单时间', prop: 'createTime', type: 'customize', width: '105', fixed: 'left' },
        { label: '发单人', type: 'userInfoCard', width: 165 },
        { label: '产品名称', prop: 'goodsName' },
        { label: '货主运价', prop: 'price', type: 'customize', rulesObj: this.$CONSTANT.transportPriceUnitMap.O, width: '135' },
        { label: '需求数量', prop: 'needNum', type: 'customize' },
        { label: '确认车数', prop: 'confirmCarCount', width: '85' },
        { label: '实际装货', prop: 'realLoadNum', type: 'customize' },
        { label: '实际卸货', prop: 'realUnLoadNum', type: 'customize' },
        { label: '损耗', prop: 'loss', type: 'customize', width: '70' },
        { label: '货单类型', prop: 'source', type: 'transition', rulesObj: this.$CONSTANT.goodsSourceMap.O, width: '80' },
        { label: '报车类型', prop: 'goodsBillType', type: 'customize', width: '125' },
        { label: '货单状态', prop: 'state', type: 'transition', rulesObj: this.$CONSTANT.goodsStateMap.O, width: '80' }, // 0已关闭 8已报停 9运输完成 123456进行中 7运输中
        // { label: '上报车数', prop: 'carCount', width: '80' },
        { label: '装卸计划时间', prop: 'plan', type: 'customize', width: '160' },
        { label: '货单编号', prop: 'goodsSerial' },
        { label: '履约监控', prop: 'map', type: 'customize', width: '85' },
      ],
    };
  },
  methods: {
    toDetail({ id }) {
      this.$router.push(`/transport/goods/detail?id=${id}`);
    },
    // 跳转运力监控
    openMap(data) {
      if (data.id) {
        this.$router.push(`/transport/monitor?id=${data.id}`);
      }
    },
  },
};

</script>
<style lang='scss' scoped>
</style>
